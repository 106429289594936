import { useEffect, useRef, useState } from "react";
import ReceiptList from "./ReceiptsList";
import DashboardHeader from "../DashboardHeader";
import DashboardFilters from "../DashboardFilters";
import { useGetReceiptsQuery } from "../../store/services/receiptsServices";
import { useNavigate } from "react-router";
import Pagination from "../Pagination";
import CircularProgress from '@mui/material/CircularProgress';
import ReceiptDashboardTable from "../ReceiptDashboardTable";
import ReactModal from "../ReactModal";
import ReceiptsList from "./ReceiptsList";
import ReceiptView from "../ReceiptView";
import { useSelector } from "react-redux";
import NoReceiptsFound from "../NoReceiptsFound";

const submittedReceipts = [
    {
        id: 1,
        user_name: 'shend',
        vat_number: 3784782,
        business_name: 'Viva Fresh',
        address: 'Ilaz Kodra',
        vat_amount: 5,
        total_amount: 20,
        status: 'pending',
        created_at: '22/11/2024',
        deleted_at: null
    },
    {
        id: 2,
        user_name: 'shend',
        vat_number: 3784782,
        business_name: 'Viva Fresh',
        address: 'Ilaz Kodra',
        vat_amount: 5,
        total_amount: 20,
        status: 'pending',
        created_at: '22/11/2024',
        deleted_at: null
    },
    {
        id: 3,
        user_name: 'shend',
        vat_number: 3784782,
        business_name: 'Viva Fresh',
        address: 'Ilaz Kodra',
        vat_amount: 5,
        total_amount: 20,
        status: 'pending',
        created_at: '22/11/2024',
        deleted_at: null
    },
    {
        id: 4,
        user_name: 'shend',
        vat_number: 3784782,
        business_name: 'Viva Fresh',
        address: 'Ilaz Kodra',
        vat_amount: 5,
        total_amount: 20,
        status: 'pending',
        created_at: '22/11/2024',
        deleted_at: null
    },
    {
        id: 5,
        user_name: 'shend',
        vat_number: 3784782,
        business_name: 'Viva Fresh',
        address: 'Ilaz Kodra',
        vat_amount: 5,
        total_amount: 20,
        status: 'pending',
        created_at: '22/11/2024',
        deleted_at: null
    },
    {
        id: 6,
        user_name: 'shend',
        vat_number: 3784782,
        business_name: 'Viva Fresh',
        address: 'Ilaz Kodra',
        vat_amount: 5,
        total_amount: 20,
        status: 'pending',
        created_at: '22/11/2024',
        deleted_at: null
    },
    {
        id: 7,
        user_name: 'shend',
        vat_number: 3784782,
        business_name: 'Viva Fresh',
        address: 'Ilaz Kodra',
        vat_amount: 5,
        total_amount: 20,
        status: 'pending',
        created_at: '22/11/2024',
        deleted_at: null
    },
    {
        id: 8,
        user_name: 'shend',
        vat_number: 3784782,
        business_name: 'Viva Fresh',
        address: 'Ilaz Kodra',
        vat_amount: 5,
        total_amount: 20,
        status: 'pending',
        created_at: '22/11/2024',
        deleted_at: null
    }
]

export default function ReceiptsMain() {

    const [filteredReceipts, setFilteredReceipts] = useState([])
    const [filterSelected, setFilterSelected] = useState("")
    const [showFilters, setShowFilters] = useState(true)
    const [pages, setPages] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [params, setParams] = useState({})
    const [searchTextInput, setSearchTextInput] = useState('')
    const [receiptsSelected, setReceiptsSelected] = useState([])
    const [pauseOnFilter, setPauseOnFilter] = useState("")
    const receipt = useSelector(state => state.receipt_view)
    const isOpen = receipt.isOpen

    const [query, setQuery] = useState(new URLSearchParams(window.location.search))

    // const { data, isLoading, isError, isSuccess } = useGetReceiptsQuery()

    const { data, isLoading, isFetching, isError, error, isSuccess } = useGetReceiptsQuery({ params })
    const navigate = useNavigate()

    if (isError) {
        console.log("Error details", error)
    }

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    useEffect(() => {
        if (data) {
            setFilteredReceipts(data?.data)
            setPages({ page: data.currentPage, pageSize: data.totalPages, total: data.totalCount })
        }
    }, [data])

    useEffect(() => {
        const newParams = {}
        query.forEach((value, key) => {
            newParams[key] = value
        })
        setParams(newParams);
        if (query.has("page")) {
            setCurrentPage(parseInt(query.get("page")))
        }
        if (query.has("status")) {
            setFilterSelected(query.get("status"))
        }
        if (query.has("title")) {
            setSearchTextInput(query.get("title"))
        }
        if (query.has("start_date")) {
            setDateRange([
                {
                    startDate: new Date(query.get("start_date")),
                    endDate: new Date(query.get("end_date")),
                    key: 'selection'
                }
            ]);
        }
    }, [query])

    useEffect(() => {
        const urlParams = new URLSearchParams(params).toString()
        navigate(`/submitted_receipts?${urlParams}`)
    }, [params, navigate])


    const [isDateRangeOpen, setIsRangeDateOpen] = useState(false)

    const [dateBtnLabel, setDateBtnLabel] = useState('')

    useEffect(() => {
        const formattedStartDate = dateRange[0].startDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        });

        const formattedEndDate = dateRange[0].endDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        });

        setDateBtnLabel(`${formattedStartDate} - ${formattedEndDate}`)
    }, [dateRange])

    const handleIsDateRangeOpen = (isDateRangeOpen) => {
        setIsRangeDateOpen(!isDateRangeOpen)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
        setParams({ ...params, page: page })
    }

    useEffect(() => {
        console.log({ params }, 'params ne component')
    }, [params])

    const handleFilterSelect = (e) => {
        const updatedParams = { ...params }
        delete updatedParams['pause_on']
        setParams({ ...updatedParams, status: e.target.id, page: 1 })
        setPauseOnFilter("")
        setCurrentPage(1)
        if (e.target.id === 'Te gjitha') {
            setFilterSelected("")
            const updatedParams = { ...params };
            delete updatedParams['status'];
            delete updatedParams['pause_on'];
            setParams(updatedParams)
            return;
        }
        setFilterSelected(e.target.innerText)
    }

    const handlePauseOnFilter = () => {
        setPauseOnFilter("on")
        setFilterSelected("Approved")
        setParams({ ...params, pause_on: 1, status: 'Approved', page: 1 })
    }

    const handlePauseOffFilter = () => {
        setPauseOnFilter("off")
        setFilterSelected("Approved")
        setParams({ ...params, pause_on: 0, status: 'Approved', page: 1 })
    }

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    useEffect(() => {
        // if (!filterSelected.length) {
        //     setFilteredReceipts(data)
        //     return;
        // }
        // if (filterSelected) {
        //     setFilteredReceipts(data.receipts.filter(receipt => receipt.status === filterSelected.toLowerCase()))
        // }
    }, [filterSelected])

    const handleReceiptsSelected = (selectedReceipt) => {
        const updatedReceiptsSelected = [...receiptsSelected]
        if (receiptsSelected.includes(selectedReceipt)) {
            updatedReceiptsSelected.filter(receipt => receipt !== selectedReceipt)
            setReceiptsSelected(updatedReceiptsSelected)
            return;
        }
        updatedReceiptsSelected.push(selectedReceipt)
        setReceiptsSelected(updatedReceiptsSelected)
    }

    const debounceTimeoutRef = useRef(null);

    const handleSearchTextInputChange = (e) => {
        const text = e.target.value
        setSearchTextInput(text)

        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        if (text.length > 2) {
            debounceTimeoutRef.current = setTimeout(() => {
                setParams({ ...params, title: text })
            }, 500)
        }
        else {
            const updatedParams = { ...params }
            delete updatedParams['title']
            setParams(updatedParams)
        }
    }

    const handleDateRangeChange = (item) => {
        setDateRange([item.selection])
        const start_date = item.selection.startDate || null
        const end_date = item.selection.endDate || null

        const start_year = start_date.getFullYear();
        const start_month = String(start_date.getMonth() + 1).padStart(2, '0');
        const start_day = String(start_date.getDate()).padStart(2, '0');
        const formattedStartDate = `${start_year}-${start_month}-${start_day}`;

        const end_year = end_date.getFullYear();
        const end_month = String(end_date.getMonth() + 1).padStart(2, '0');
        const end_day = String(end_date.getDate()).padStart(2, '0');
        const formattedEndDate = `${end_year}-${end_month}-${end_day}`;
        setParams({ ...params, start_date: formattedStartDate, end_date: formattedEndDate })
    }

    const removeDateFilter = () => {
        const updatedParams = { ...params };
        delete updatedParams['start_date'];
        delete updatedParams['end_date'];
        setParams(updatedParams)
        setIsRangeDateOpen(false)
        setDateRange([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ])
        return;
    }

    const buttons = [
        {
            label: '',
            color: 'bg-white',
            border: 'border border-gray-300 invisible',
            linkTo: '/property_types'
        }
    ]

    const details = {
        title: 'Submitted Receipts',
        count: data?.totalCount
    }

    const tableData = {
        headLabels: ['ID', 'Submission Date', 'VAT Number', 'User', 'Business Name', 'VAT Amount', 'Total Amount', 'Status'],
        list: <ReceiptsList receipts={filteredReceipts} />,
        data: filteredReceipts,
        receiptsSelected: receiptsSelected,
        handleReceiptsSelected: handleReceiptsSelected,
        actions: true
    }

    const filters = {
        categories: [],
        selectedFilter: filterSelected,
        handleFilterSelect: handleFilterSelect,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters,
        dateRange: dateRange,
        setDateRange: setDateRange,
        isDateRangeOpen: isDateRangeOpen,
        handleIsDateRangeOpen: handleIsDateRangeOpen,
        dateBtnLabel: dateBtnLabel,
        handleDateRangeChange: handleDateRangeChange,
        removeDateFilter: removeDateFilter,
        params: params,
        updatedStatusCount: filteredReceipts?.updatedStatusCount,
        searchTextInput: searchTextInput,
        handleSearchTextInputChange: handleSearchTextInputChange,
        handlePauseOnFilter: handlePauseOnFilter,
        handlePauseOffFilter: handlePauseOffFilter,
        pauseOnFilter: pauseOnFilter
    }

    // if (isFetching) return (
    //     <>\
    // <div className="w-full h-screen flex items-center justify-center text-primary"><CircularProgress color="var(--primary-color)" /></div>
    //     </>
    // )

    if (error?.status === 404) {
        return (
            <NoReceiptsFound />
        )
    }

    return (
        <>
            {isOpen && <ReceiptView />}
            <ReactModal />
            <div className="h-full flex flex-col">
                <div className="">
                    <DashboardHeader buttons={buttons} details={details} />
                    <hr />
                    <DashboardFilters filters={filters} />
                </div>
                <div className="flex flex-col justify-between h-full">
                    <div className="w-full relative">
                        {isLoading ? (
                            <div className="w-full z-30 absolute top-0 left-0 bg-transparent backdrop-blur-sm h-[800px] flex items-center justify-center text-primary">
                                <CircularProgress size={40} color="var(--primary-color)" />
                            </div>
                        ) : (
                            isFetching && (
                                <div className="w-full z-30 absolute top-0 left-0 bg-transparent backdrop-blur-sm h-full flex items-center justify-center text-primary">
                                    <CircularProgress size={40} color="var(--primary-color)" />
                                </div>
                            )
                        )}
                        <ReceiptDashboardTable tableData={tableData} />
                    </div>
                    {!isLoading && <Pagination currentPage={currentPage} totalPages={Math.ceil(data?.totalCount / 10)} onPageChange={handlePageChange} />}
                </div>
            </div>
        </>
    )
}