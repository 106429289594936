import { PiArrowLeftBold, PiArrowRightBold } from "react-icons/pi"
import DashboardFilters from "../DashboardFilters"
import DashboardHeader from "../DashboardHeader"
import DashboardTable from "../DashboardTable"
import { useEffect, useState } from "react"
import PrizesListItem from "./PrizesListItem"
import PrizesList from "./PrizesList"

const prizes = [
    {
        id: 1,
        winner: 'Arber Bakija',
        email: 'arberbakija@bbros.eu',
        amount: 100,
        date: '12/11/2024',
        payment_type: 'Basic',
    },
    {
        id: 2,
        winner: 'Arber Bakija',
        email: 'arberbakija@bbros.eu',
        amount: 100,
        date: '12/11/2024',
        payment_type: 'Basic',
    },
    {
        id: 3,
        winner: 'Arber Bakija',
        email: 'arberbakija@bbros.eu',
        amount: 100,
        date: '12/11/2024',
        payment_type: 'Basic',
    },
    {
        id: 4,
        winner: 'Arber Bakija',
        email: 'arberbakija@bbros.eu',
        amount: 100,
        date: '12/11/2024',
        payment_type: 'Basic',
    },
    {
        id: 5,
        winner: 'Arber Bakija',
        email: 'arberbakija@bbros.eu',
        amount: 100,
        date: '12/11/2024',
        payment_type: 'Basic',
    },
    {
        id: 6,
        winner: 'Arber Bakija',
        email: 'arberbakija@bbros.eu',
        amount: 100,
        date: '12/11/2024',
        payment_type: 'Basic',
    },
    {
        id: 7,
        winner: 'Arber Bakija',
        email: 'arberbakija@bbros.eu',
        amount: 100,
        date: '12/11/2024',
        payment_type: 'Basic',
    },
    {
        id: 8,
        winner: 'Arber Bakija',
        email: 'arberbakija@bbros.eu',
        amount: 100,
        date: '12/11/2024',
        payment_type: 'Basic',
    },
]

export default function PrizesMain() {

    const [filteredPrizes, setFilteredPrizes] = useState(prizes || null)
    const [filterSelected, setFilterSelected] = useState("")
    const [showFilters, setShowFilters] = useState(true)

    const handleFilterSelect = (e) => {
        if (e.target.id === 'Te gjitha') {
            setFilterSelected("")
            return;
        }
        setFilterSelected(e.target.innerText)
    }

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    useEffect(() => {
        if (!filterSelected.length) {
            setFilteredPrizes(prizes)
            return;
        }
        setFilteredPrizes(prizes.filter(prize => prize.payment_type === filterSelected))
    }, [filterSelected])

    const buttons = [
        { label: '', color: 'bg-white invisible', border: 'border border-[#D0D5DD]', shadow: true, onClick: null, linkTo: '/payments/archived' }
    ]

    const details = {
        title: 'Prizes',
        count: prizes.length
    }

    const tableData = {
        headLabels: ['ID', 'Name & Surname', 'Email', 'Amount', 'Payment Type', 'Date'],
        list: <PrizesList prizes={prizes} />,
        data: filteredPrizes,
        actions: true
    }

    const filters = {
        categories: [],
        selectedFilter: filterSelected,
        handleFilterSelect: handleFilterSelect,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters
    }

    return (
        <>
            <DashboardHeader buttons={buttons} details={details} />
            <hr />
            <DashboardFilters filters={filters} />
            <div className="w-full">
                <DashboardTable tableData={tableData} />
            </div>
            <div className="p-4 flex justify-between items-center w-full text-sm font-semibold">
                <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                    <PiArrowLeftBold style={{ fontSize: '18px' }} />
                    <div className="leading-3">Previous</div>
                </div>
                <div>Pages</div>
                <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                    <div className="leading-3">Next</div>
                    <PiArrowRightBold style={{ fontSize: '18px' }} />
                </div>
            </div>
        </>
    )
}