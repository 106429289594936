export default function NoReceiptsFound() {
    return (
        <div className="w-full h-full flex items-center justify-center text-xl">
            <div className="flex flex-col items-center">
                <div>
                    <img src="no_receipts.png" alt="no-receipts" />
                </div>
                <div>No receipts found</div>
            </div>
        </div>
    )
}