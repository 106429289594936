import { useEffect, useState } from "react";
import DashboardHeader from "../DashboardHeader";
import DashboardFilters from "../DashboardFilters";
import DashboardTable from "../DashboardTable";
import ArchivedReceiptsList from "./ArchivedReceiptsList";
import { useGetArchivedReceiptsQuery } from "../../store/services/receiptsServices";
import { useNavigate } from "react-router";
import Pagination from "../Pagination";
import ReactModal from "../ReactModal";
import { useSelector } from "react-redux";
import ReceiptView from "../ReceiptView";
import ArchivedReceiptView from "../ArchivedReceiptView";
import NoReceiptsFound from "../NoReceiptsFound";
import { CircularProgress } from "@mui/material";

export default function ArchivedReceipts() {

    const [filteredArchivedReceipts, setFilteredArchivedReceipts] = useState([])
    const [filterSelected, setFilterSelected] = useState("")
    const [showFilters, setShowFilters] = useState(true)
    const [pages, setPages] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    // const [searchParams, setSearchParams] = useSearchParams();
    const [params, setParams] = useState({})
    const receipt = useSelector(state => state.archived_receipt_view)
    const isOpen = receipt.isOpen

    useEffect(() => {
        console.log({ receipt }, 'from redux store archived')
    }, [receipt])

    const { data, isLoading, isFetching, isError } = useGetArchivedReceiptsQuery(params)

    const [query, setQuery] = useState(new URLSearchParams(window.location.search))
    const navigate = useNavigate()

    useEffect(() => {
        if (data) {
            console.log({ data })
            setFilteredArchivedReceipts(data?.data)
            setPages({ page: data.currentPage, pageSize: data.totalPages, total: data.totalCount })
        }
    }, [data])

    useEffect(() => {
        const newParams = {}
        query.forEach((value, key) => {
            newParams[key] = value
        })
        setParams(newParams);
        if (query.has("page")) {
            setCurrentPage(parseInt(query.get("page")))
        }
        if (query.has("status")) {
            setFilterSelected(query.get("status"))
        }
    }, [query])

    useEffect(() => {
        const urlParams = new URLSearchParams(params).toString()
        navigate(`/archived_receipts?${urlParams}`)
    }, [params, navigate])

    const handleFilterSelect = (e) => {
        setParams({ ...params, status: e.target.id, page: 1 })
        if (e.target.id === 'Te gjitha') {
            setFilterSelected("")
            const updatedParams = { ...params };
            delete updatedParams['status'];
            setParams(updatedParams)
            return;
        }
        setFilterSelected(e.target.innerText)
    }

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
        setParams({ ...params, page: page })
    }

    const handleSearch = (e) => {
        // if (!e.target.value) {
        //     setFilteredReceipts(data)
        //     return
        // }
        // const filteredReceipts = { ...data, data: data.data.filter(receipt => receipt.name.toLowerCase().startsWith(e.target.value.toLowerCase())) }
        // setFilteredReceipts(filteredReceipts)
    }

    useEffect(() => {
        // if (!filterSelected.length) {
        //     setFilteredArchivedReceipts(archived_receipts)
        //     return;
        // }
        // setFilteredArchivedReceipts(archived_receipts.filter(receipt => receipt.status === filterSelected))
    }, [filterSelected])

    const details = {
        title: 'Archived Receipts',
        count: data?.totalCount,
    }

    const buttons = [
        {
            label: '',
            color: 'bg-white invisible',
            border: 'border border-[#D0D5DD]',
            linkTo: '/receipts',
            onClick: null,
            shadow: true
        }
    ]

    const tableData = {
        headLabels: ['ID', 'Submission Date', 'VAT Number', 'User', 'Business Name', 'VAT Amount', 'Total Amount', 'Status'],
        list: <ArchivedReceiptsList receipts={filteredArchivedReceipts} />,
        data: filteredArchivedReceipts,
        actions: true
    }

    const filters = {
        categories: [],
        selectedFilter: filterSelected,
        handleFilterSelect: handleFilterSelect,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters
    }

    if (isError?.status === 404) {
        return (
            <NoReceiptsFound />
        )
    }

    return (
        <>
            {isOpen && <ArchivedReceiptView />}
            <ReactModal />
            <div className="h-full flex flex-col">
                <div className="">
                    <DashboardHeader buttons={buttons} details={details} />
                    <hr />
                    <DashboardFilters filters={filters} />
                </div>
                <div className="flex flex-col justify-between h-full">
                    <div className="w-full relative">
                        {isLoading ? (
                            <div className="w-full z-30 absolute top-0 left-0 bg-transparent backdrop-blur-sm h-[800px] flex items-center justify-center text-primary">
                                <CircularProgress size={40} color="var(--primary-color)" />
                            </div>
                        ) : (
                            isFetching && (
                                <div className="w-full z-30 absolute top-0 left-0 bg-transparent backdrop-blur-sm h-full flex items-center justify-center text-primary">
                                    <CircularProgress size={40} color="var(--primary-color)" />
                                </div>
                            )
                        )}
                        <DashboardTable tableData={tableData} />
                    </div>
                </div>
                <Pagination currentPage={currentPage} totalPages={Math.ceil(data?.totalCount / 10)} onPageChange={handlePageChange} />
                {/* <div className="p-4 flex justify-between items-center w-full text-sm font-semibold">
                <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                    <PiArrowLeftBold style={{ fontSize: '18px' }} />
                    <div className="leading-3">Previous</div>
                </div>
                <div>Pages</div>
                <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                    <div className="leading-3">Next</div>
                    <PiArrowRightBold style={{ fontSize: '18px' }} />
                </div>
            </div> */}
            </div>
        </>
    )
}