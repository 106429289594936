import { FiEdit } from "react-icons/fi";
import { LuTrash2 } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import { tBodyItemStyle } from "../users/UserListItem";
import { useDispatch } from "react-redux";
import { modalActions } from "../../store/modalSlice";
import ReactModal from "../ReactModal";

const removeHtmlTags = (html) => {
    return html?.replace(/<\/?[^>]+(>|$)/g, "")
}

export default function NotificationsListItem({ notification }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handlenotificationDelete = (e) => {
        e.stopPropagation();
        dispatch(modalActions.openModal({ details: { id: notification.id, name: notification.title, category: 'notification' } }))
    }

    const handleRowClick = () => {
        // navigate(`/notifications/${notification.id}/edit`)
    }

    return (
        <>
            <tr className="table_row" onClick={handleRowClick}>
                <td style={tBodyItemStyle} className="text-black font-medium w-[15%]">{notification.id}</td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[20%]">{notification.title}</td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[20%]">
                    {removeHtmlTags(notification.body?.slice(0, 30))}
                    {notification.body.length > 30 && '...'}
                </td>
                <td style={tBodyItemStyle} className="w-[20%]">{notification.created_at}</td>
                <td style={{ ...tBodyItemStyle, padding: '0px 20px', }} className="">
                    <div className="w-[100px] h-[50px]">
                        {/* <img src={`${process.env.REACT_APP_BACKEND_HOST}/${notification.image_path}`} alt="notification_img" className="w-full h-full object-cover rounded-xl" /> */}
                        <img src={`${process.env.REACT_APP_BACKEND_HOST}/api/${notification.image_path}`} alt="notification_img" className="w-full h-full object-cover rounded-xl" />
                    </div>
                </td>
            </tr>
        </>
    )
}