import { useSelector } from "react-redux"
import ReceiptsListItem from "./ReceiptsListItem"

export default function ReceiptsList({ receipts }) {

    const selectedReceipts = useSelector(state => state.selectedReceipts.receipts)

    return (
        <>
            {receipts.map((receipt, index) => (
                <ReceiptsListItem key={index} receipt={receipt} isSelected={selectedReceipts.some(selectedReceipt => selectedReceipt.receipt_id === receipt.receipt_id)} />
            ))}
        </>
    )
}