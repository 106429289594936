export default function DashboardTable({ tableData }) {
    
    const { headLabels, list, data, actions } = tableData

    const tHeadStyle = {
        padding: '10px 20px',
        fontSize: '12px',
        fontWeight: '500',
        color: '#595959',
        textWrap: 'nowrap'
    }
    
    return (
        <table className="w-full text-[14px] text-[#595959]">
            <thead>
                <tr className="bg-[#F9FAFB] border-b border-[#eee]">
                    {headLabels.map(label => (
                        <td key={label.id} style={tHeadStyle}>{label}</td>
                    ))}
                    {actions && <td style={tHeadStyle} className="text-center">Actions</td>}
                </tr>
            </thead>
            {data && (
                <tbody className="[&>*:nth-child(even)]:bg-[#F9FAFB]">
                    {list}
                </tbody>
            )}
        </table>
    )
}