import { useEffect, useRef, useState } from "react";
import DashboardHeader from "../DashboardHeader";
import { MdDone } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RiUploadCloud2Line } from "react-icons/ri";
import { FiTrash2 } from "react-icons/fi";
import { useCreateBlogMutation } from "../../store/services/blogServices";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { openModalWithTimeout } from "../../store/alertSlice";
import { labelStyle } from "../users/EditUser";
import { useLazySearchUsersQuery, useSendNotificationMutation } from "../../store/services/notificationServices";
import { ClipLoader } from "react-spinners";
import { IoClose } from "react-icons/io5";

export const removeHtmlTags = (html) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "")
}

export const categories = ['Car equipment', 'Car maintenance', 'Car purchase', 'Car sales', 'Camping tips']
export const cities = [
    {
        name: "Prishtine",
        zip_code: '10000'
    },
    {
        name: "Mitrovice",
        zip_code: '40000',
    },
    {
        name: "Peje",
        zip_code: '30000'
    },
    {
        name: "Prizren",
        zip_code: '20000',
    },
    {
        name: "Ferizaj",
        zip_code: '70000',
    },
    {
        name: "Gjilan",
        zip_code: '60000',
    },
    {
        name: "Gjakove",
        zip_code: '50000',
    },
]
// export const users = [
//     {
//         id: 1,
//         email: 'shendiiiiii@gmail.com'
//     },
//     {
//         id: 2,
//         email: 'armir@gmail.com'
//     },
//     {
//         id: 3,
//         email: 'shendzhubi@gmail.com'
//     },
//     {
//         id: 4,
//         email: 'armirqurdina@gmail.com'
//     },
//     {
//         id: 5,
//         email: 'test@gmail.com'
//     },
//     {
//         id: 6,
//         email: 'shend@gmail.com'
//     },
//     {
//         id: 7,
//         email: 'arber@gmail.com'
//     },
//     {
//         id: 8,
//         email: 'arberbakija@gmail.com'
//     },
//     {
//         id: 9,
//         email: 'sample@gmail.com'
//     },
//     {
//         id: 10,
//         email: 'testtttt@test.com'
//     }
// ]

export default function AddNotification() {

    const [createItem] = useCreateBlogMutation();
    const [searchTextInput, setSearchTextInput] = useState('')
    const [selectText, setSelectText] = useState('')
    const [searchList, setSearchList] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const dispatch = useDispatch()
    const [isSelectByCityEnabled, setIsSelectByCityEnabled] = useState(false)
    const [selectedCities, setSelectedCities] = useState([])

    const [searchUsers, { data: users }] = useLazySearchUsersQuery()
    const [usersFound, setUsersFound] = useState([])

    const [htmlText, setHtmlText] = useState('');

    const [image, setImage] = useState(null)
    const [imagePreview, setImagePreview] = useState('')
    const [imageUploadedTime, setImageUploadedTime] = useState('')

    const [errors, setErrors] = useState({})

    const fileInputRef = useRef(null);

    const navigate = useNavigate()

    const [sendNotification, { isLoading }] = useSendNotificationMutation();

    const [notification, setNotification] = useState({
        title: '',
        body: '',
    })

    const [notifyEveryone, setNotifyEveryone] = useState(false)

    const handleBlogChange = (e) => {
        setNotification({ ...notification, [e.target.name]: e.target.value })
        if (e.target.value) {
            let currentErrors = errors;
            delete currentErrors[e.target.name]
            setErrors(currentErrors)
        }
    }

    const validateForm = () => {
        let formErrors = {};
        if (!notification.title) formErrors.title = "Title is required";
        if (removeHtmlTags(notification.body).length < 1) formErrors.body = "Text is required";
        // if (!image) formErrors.image = "Image is required"
        if (selectedUsers.length < 1 && selectedCities.length < 1 && !notifyEveryone) formErrors.recipients = "Recipient is required"

        setErrors(formErrors);

        return Object.keys(formErrors).length === 0;
    };

    const details = {
        title: 'Add Notification',
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const notificationData = {
            title: notification.title,
            body: notification.body.replace(/<\/?[^>]+(>|$)/g, ""),
            data: {
                userIds: selectedUsers.map(user => user.id),
                zip_codes: selectedCities.map(city => city.zip_code),
                notifyEveryone
            },
        };

        if (validateForm()) {
            try {

                const formData = new FormData();

                formData.append('notification', JSON.stringify(notificationData))

                if (image) {
                    formData.append('image', image)
                }

                const result = await sendNotification(formData).unwrap();
                console.log('Notification sent!', result);
                navigate('/notifications');
                dispatch(openModalWithTimeout({ text: 'Notification was sent successfully!', type: 'success' }, 3000));
            } catch (error) {
                dispatch(openModalWithTimeout({ text: error.data.message, type: 'error' }, 3000));
                console.error('Failed to send notification:', error);
            }
        } else {
            console.log("Validation errors");
        }
    };

    const buttons = [
        {
            label: 'Cancel',
            color: 'bg-white',
            border: 'border border-[#D0D5DD]',
            shadow: 'shadow-sm',
            linkTo: "/notifications",
            onClick: null,
        },
        {
            label: !isLoading ? 'Save' : <div className="flex items-center"><ClipLoader color="white" size={16} /></div>,
            icon: !isLoading && <MdDone />,
            color: 'bg-primary text-white',
            border: 'border border-b-2 border-primary',
            shadow: 'shadow-md',
            linkTo: null,
            onClick: handleFormSubmit,
        }
    ]

    const toolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        ['link'],
    ];


    const module = {
        toolbar: toolbarOptions
    }

    useEffect(() => {
        if (image) {
            setImagePreview(URL.createObjectURL(image))
            setImageUploadedTime(new Date(Date.now()).toLocaleString())
        }
    }, [image])

    useEffect(() => {
        setNotification({ ...notification, body: htmlText })
    }, [htmlText])

    const handleChange = (content, delta, source, editor) => {
        setHtmlText(content);
        if (content) {
            let currentErrors = errors;
            delete currentErrors.body
            setErrors(currentErrors)
        }
    };

    const editorStyle = {
        height: '300px',
    }

    const handlePictureChange = (e) => {
        setImage(e.target.files[0])
        if (e.target.files[0]) {
            let currentErrors = errors;
            delete currentErrors.image
            setErrors(currentErrors)
        }
    }

    const handleClearFileInput = () => {
        fileInputRef.current.value = '';
        setImage(null)
        setImagePreview(null)
    }

    const debounceTimeoutRef = useRef(null);

    const handleReceipientsSearchChange = (e) => {
        const text = e.target.value
        setSearchTextInput(text)

        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        if (text.length > 2) {
            debounceTimeoutRef.current = setTimeout(async () => {
                // trigger the users function
                const result = await searchUsers(text)
                if (result?.data) {
                    setUsersFound(result.data)
                }
            }, 500)
        }
        else {
            setSearchList([])
        }
    }

    useEffect(() => {
        setSearchList(usersFound)
    }, [usersFound])

    useEffect(() => {
        if (selectedUsers.length > 0 || selectedCities.length > 0) {
            console.log('armir')
            let currentErrors = { ...errors };
            delete currentErrors['recipients']
            setErrors(currentErrors)
        }
    }, [selectedUsers, selectedCities])

    const handleUserClick = (e) => {
        const userSelectedEmail = e.target.getAttribute('name')
        const userSelected = users.find(user => user.email === userSelectedEmail)

        if (selectedUsers.some(user => user.email === userSelectedEmail)) {
            setSelectedUsers(selectedUsers.filter(user => user.email !== userSelectedEmail))
            return;
            setSearchList([])
        }
        setSelectedUsers([...selectedUsers, userSelected])
        setSearchList([])
        setSearchTextInput('')
        setUsersFound([])
    }

    const handleZipCodeChange = (e) => {
        setSelectText('')
        const currentCities = [...selectedCities]
        const selectedZipCode = e.target.value;
        if (currentCities.find(city => city.zip_code === selectedZipCode)) {
            setSelectedCities(currentCities.filter(city => city.zip_code !== selectedZipCode))
            return;
        }
        const selectedCity = cities.find(city => city.zip_code === selectedZipCode);
        setSelectedCities([...selectedCities, selectedCity])
    }

    const removeUserFromRecipients = (e) => {
        const email = e.target.getAttribute('name')
        const users = selectedUsers.filter(user => user.email !== email)
        setSelectedUsers(users)
    }

    const removeCityFromRecipients = (e) => {
        const zip_code = e.target.getAttribute('name')
        const cities = selectedCities.filter(city => city.zip_code !== zip_code)
        setSelectedCities(cities)
    }

    const handleNotifyEveryoneChange = () => {
        setNotifyEveryone(!notifyEveryone)
        setSelectedUsers([])
        setSelectedCities([])
        setSearchList([])
        setSearchTextInput('')
    }

    return (
        <div>
            <DashboardHeader details={details} buttons={buttons} />
            <div className="bg-[#F9FAFB] rounded-b-xl">
                <hr />
                <div className="p-6 rounded-b-xl text-sm">
                    <form>
                        <div className="flex gap-8">
                            <div className="w-full">
                                <div>
                                    <label htmlFor="title">Title</label>
                                    <input type="text" id="title" name="title" value={notification.title} onChange={handleBlogChange} className="block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 font-medium" />
                                </div>
                                {errors.title && <div className="text-bloodred font-bold">{errors.title}</div>}
                            </div>
                            <div className="w-full flex gap-4">
                                <div className="w-full relative">
                                    <div>
                                        <label className={labelStyle} htmlFor="users">Recipients</label>
                                        <input
                                            type="text"
                                            id="users"
                                            name="users"
                                            value={searchTextInput}
                                            className={`block rounded-md border border-gray-300 w-full p-4 my-1 mt-2`}
                                            onChange={handleReceipientsSearchChange}
                                            disabled={notifyEveryone}
                                            autoComplete="off"
                                        />
                                    </div>
                                    {errors.recipients && <div className="text-bloodred font-bold">{errors.recipients}</div>}
                                    {searchList.length > 0 && (
                                        <div className="absolute border bg-white w-full z-40 rounded-md">
                                            {searchList.map((user, index) => (
                                                <div key={index} name={user.email} className="p-2 px-4 hover:bg-gray-100 cursor-pointer" onClick={handleUserClick}>{user.email}</div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="w-full">
                                    <label htmlFor="popular_topics">
                                        <div className="flex items-center gap-2">
                                            {/* <input name="by_city" id="by_city" type="checkbox" checked={isSelectByCityEnabled} onChange={() => setIsSelectByCityEnabled(!isSelectByCityEnabled)} className="accent-primary" /> */}
                                            <label htmlFor="by_city">Recipients by city</label>
                                        </div>
                                    </label>
                                    <div className="relative w-full">
                                        <select name="popular_topics" id="popular_topics" value={selectText} className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2" onChange={handleZipCodeChange} disabled={notifyEveryone} >
                                            <option value="" disabled>Select city</option>
                                            {cities.map((city, index) => (
                                                <option key={index} value={city.zip_code}>{city.name}</option>
                                            ))}
                                        </select>
                                        <div className="absolute top-1/2 right-0 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                            <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center gap-2 ">
                            <label htmlFor="notify-everyone">Notify everyone</label>
                            <input type="checkbox" name="notify-everyone" id="notify-everyone" className="accent-primary w-4 h-4" checked={notifyEveryone} onChange={handleNotifyEveryoneChange} />
                        </div>
                        {(selectedUsers.length > 0 || selectedCities.length > 0) && (
                            <div className="flex items-center gap-4 mt-4 w-full flex-wrap">
                                {selectedUsers.length > 0 && selectedUsers.map((user, index) => (
                                    <div className="text-sm p-1 px-3 border border-blue-300 text-blue-800 bg-blue-50 rounded-full flex items-center gap-1">
                                        <div>{user.email}</div>
                                        <IoClose size={18} name={user.email} className="text-gray-400 hover:text-red-600 hover:cursor-pointer" onClick={removeUserFromRecipients} />
                                    </div>
                                ))}
                                {selectedCities.length > 0 && (
                                    selectedCities.map((city, index) => (
                                        <div key={index} className="text-sm p-1 px-3 border border-blue-300 text-blue-800 bg-blue-50 rounded-full flex items-center gap-1">
                                            <div>All users from {city.name}</div>
                                            <IoClose size={18} name={city.zip_code} className="text-gray-400 hover:text-red-600 hover:cursor-pointer" onClick={removeCityFromRecipients} />
                                        </div>
                                    ))
                                )}
                            </div>
                        )}
                    </form>
                </div>
                {/* <Slate editor={editor} initialValue={initialValue}>
                <Editable/>
            </Slate> */}
                {errors.body && <div className="text-bloodred px-6 pb-2 text-sm font-bold">{errors.body}</div>}
                <ReactQuill theme="snow" modules={module} value={htmlText} onChange={handleChange} style={editorStyle} className="m-6 mb-12 mt-0 no-scrollbar z-20" />
                <div className="p-6">
                    {image && (
                        <div className="mb-8">
                            <h1 className="my-4 font-semibold text-gray-600">Fotot e ngarkuara</h1>
                            <div className="flex items-center gap-8">
                                <div className="flex flex-col gap-1">
                                    <div className="font-bold">{image.name}</div>
                                    <div className="text-gray-400 text-sm">{imageUploadedTime}</div>
                                </div>
                                <div className="w-32 h-16 bg-blue-400 rounded-xl">
                                    <img src={`${imagePreview}`} alt="img" className="w-full h-full object-cover rounded-xl" />
                                </div>
                            </div>
                        </div>
                    )}
                    {errors.image && <div className="text-bloodred text-sm pb-2 font-bold">{errors.image}</div>}
                    <input
                        id="files"
                        name="files"
                        type="file"
                        ref={fileInputRef}
                        accept="image/png, image/jpg, image/jpeg, image/svg"
                        onChange={handlePictureChange}
                        className="hidden"
                    />
                    {image ? (
                        <div className="h-[280px] flex items-center w-full p-2 bg-[#FBFEFC] rounded-xl border-2 border-dashed border-green-300 flex justify-center">
                            <div className="my-4">
                                <div className="rounded-full w-14 h-14 bg-[#E7F6EC] flex justify-center items-center mx-auto cursor-pointer">
                                    <div className="rounded-full w-6 h-6 flex justify-center items-center bg-green-600">
                                        <MdDone fontSize={16} color="white" />
                                    </div>
                                </div>
                                <div className="text-black my-4 text-center">
                                    <span className="font-semibold">Fotoja u ngarkua</span>
                                </div>
                                <div className="p-1 px-4 w-max rounded-lg text-gray-400 mx-auto cursor-pointer flex gap-2 items-center" onClick={handleClearFileInput}>
                                    <span><FiTrash2 fontSize={16} /></span>
                                    <span className="leading-3 font-semibold">Pastro ngarkimin</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <label htmlFor="files">
                            <div className="h-[280px] flex items-center w-full p-2 bg-white rounded-xl border-2 border-dashed border-gray-200 flex justify-center cursor-pointer">
                                <div className="my-4">
                                    <div className="rounded-full w-14 h-14 bg-gray-100 flex justify-center items-center mx-auto  cursor-pointer">
                                        <RiUploadCloud2Line fontSize={28} color="#555" />
                                    </div>
                                    <div className="text-appgray my-4 text-center">
                                        <span className="text-bloodred font-semibold cursor-pointer">Kliko per te ngarkuar</span> ose terheq dhe lesho
                                    </div>
                                    <div className="p-2 w-[350px] relative my-4 text-gray-400">
                                        <hr className="border border-[whitesmoke]" />
                                        <div className="absolute top-0 left-1/2 -translate-x-1/2 bg-white px-3">
                                            OSE
                                        </div>
                                    </div>
                                    <div className="p-2 px-4 bg-bloodred w-max rounded-lg text-white mx-auto cursor-pointer">Shfleto Dosjet</div>
                                </div>
                            </div>
                        </label>
                    )}
                </div>
            </div >
        </div >
    )
}