import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../store/modalSlice';
import { useDeleteUserMutation } from '../store/services/userServices';
import { selectedReceiptsActions } from '../store/selectedReceiptsSlice';
import { openModalWithTimeout } from '../store/alertSlice';
import { useCreatePropertyTypeMutation, useGetPropertyTypesQuery } from '../store/services/propertyTypeServices';
import { createItemModalActions } from '../store/createItemModalSlice';
import { useCreateSubPropertyTypeMutation } from '../store/services/subPropertyServices';
import { useCreateHeatingTypeMutation } from '../store/services/heatingTypeServices';
import { useCreateFurnishingTypeMutation } from '../store/services/furnishingTypeServices';
import { useCreateCharacteristicMutation } from '../store/services/characteristicsServices';
import { receiptViewActions } from '../store/receiptViewSlice';
import { labelStyle } from './users/EditUser';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useGetSubmittedReceiptByIdQuery, useVerifyReceiptMutation } from '../store/services/receiptsServices';
import { CircularProgress } from '@mui/material';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px'
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.8)'
    }
};

Modal.setAppElement(document.getElementById('root'))

export default function ReceiptView() {

    const [verifyReceipt] = useVerifyReceiptMutation()

    const dispatch = useDispatch()
    const receipt = useSelector(state => state.receipt_view)
    const isOpen = receipt.isOpen
    const [approveConfirm, setApproveConfirm] = useState(false)
    const [rejectConfirm, setRejectConfirm] = useState(false)
    const [images, setImages] = useState([])
    const [imageUploaded, setImageUploaded] = useState(false)

    const { data, isLoading } = useGetSubmittedReceiptByIdQuery({ id: receipt.receipt.receipt_id })

    useEffect(() => {
        if (data) {
            if (Array.isArray(data.image_path)) {
                setImages(data.image_path)
            }
            else {
                setImages(JSON.parse(data.image_path))
            }
        }
    }, [data])

    function closeModal() {
        dispatch(receiptViewActions.closeModal())
        setApproveConfirm(false)
        setRejectConfirm(false)
    }

    const handleApprove = async () => {
        const data = { value: 'approve' }
        try {
            const response = await verifyReceipt({ id: receipt.receipt.receipt_id, data: data }).unwrap()
            console.log("Receipt status changed:", response)
            dispatch(openModalWithTimeout({ text: 'Receipt approved successfully', type: 'success' }, 2000))
        }
        catch {
            dispatch(openModalWithTimeout({ text: 'Failed to change receipt status', type: 'error' }, 2000))
        }
        setApproveConfirm(false)
        dispatch(receiptViewActions.closeModal())
    }

    const handleReject = async () => {
        const data = { value: 'reject' }
        try {
            const response = await verifyReceipt({ id: receipt.receipt.receipt_id, data: data }).unwrap()
            console.log("Receipt status changed:", response)
            dispatch(openModalWithTimeout({ text: 'Receipt rejected successfully', type: 'success' }, 2000))
        }
        catch {
            dispatch(openModalWithTimeout({ text: 'Failed to change receipt status', type: 'error' }, 2000))
        }
        setApproveConfirm(false)
        dispatch(receiptViewActions.closeModal())
    }

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="relative bg-white p-2 px-4 w-[800px] h-[600px]">
                    {isLoading && (
                        <div className='absolute flex items-center justify-center top-0 left-0 w-full h-full bg-white z-20'>
                            <CircularProgress color='black' size={40} />
                        </div>
                    )}
                    <div className='flex gap-8 h-full'>
                        <div className='w-1/2 h-full no-scrollbar flex justify-center items-center'>
                            <div className='w-full h-full min-h-full overflow-y-scroll scrollbar-thin scrollbar-track scrollbar-thumb'>
                                {images.map(image => (
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                        style={{ height: '100%' }}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <>
                                                <TransformComponent>
                                                    <div className='h-full w-full p-1 border border-gray-300'>
                                                        {!imageUploaded && <div className='flex items-center justify-center w-full h-full'><CircularProgress size={32} color='black' /></div>}
                                                        <div className='flex h-full w-full justify-center items-center '>
                                                            <img src={`${process.env.REACT_APP_BACKEND_HOST}/api/${image}`} alt="img" className="w-full h-full object-cover" onLoad={() => setImageUploaded(true)} />
                                                        </div>
                                                    </div>
                                                </TransformComponent>
                                            </>
                                        )}
                                    </TransformWrapper>
                                ))}
                            </div>
                        </div>
                        <div className='h-full flex flex-col justify-between w-1/2 text-sm'>
                            <div>
                                <label className={labelStyle} htmlFor="vat_number">Numri fiskal</label>
                                <input type="tel" id="vat_number" name="vat_number" value={data?.vat_number} className={`block rounded-md border border-gray-300 w-full p-4 mt-2 disabled:bg-white`} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="company">Emri i kompanise</label>
                                <input type="text" id="company" name="company" value={data?.business_name} className={`block rounded-md border border-gray-300 w-full p-4 mt-2 disabled:bg-white`} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="address">Adresa</label>
                                <input type="text" id="address" name="address" value={data?.address} className={`block rounded-md border border-gray-300 w-full p-4 mt-2 disabled:bg-white`} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="total_without_vat">Shuma pa TVSH</label>
                                <input type="text" id="total_without_vat" name="total_without_vat" value={data?.total_without_vat} className={`block rounded-md border border-gray-300 w-full p-4 mt-2 disabled:bg-white`} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="vat_amount">TVSH</label>
                                <input type="tel" id="vat_amount" name="vat_amount" value={data?.vat_amount} className={`block rounded-md border border-gray-300 w-full p-4 mt-2 disabled:bg-white`} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="total_amount">Shuma totale</label>
                                <input type="tel" id="total_amount" name="total_amount" value={data?.total_amount} className={`block rounded-md border border-gray-300 w-full p-4 mt-2 disabled:bg-white`} disabled />
                            </div>
                            <div className='flex gap-8'>
                                <div className='w-full p-2 bg-red-600 text-white text-center rounded-md cursor-pointer hover:bg-red-600/80' onClick={() => setRejectConfirm(true)}>
                                    Reject
                                </div>
                                <div className='w-full p-2 bg-green-600 text-white text-center rounded-md cursor-pointer hover:bg-green-600/80' onClick={() => setApproveConfirm(true)}>
                                    Approve
                                </div>
                            </div>
                        </div>
                    </div>
                    {approveConfirm && (
                        <div className='absolute top-0 left-0 w-full h-full bg-black/80 z-20 flex justify-center items-center'>
                            <div className='flex flex-col gap-8 items-center bg-white p-12 rounded-xl'>
                                <div className='text-xl'>Are you sure you want to approve the receipt?</div>
                                <div className='flex justify-between w-full'>
                                    <div className='p-1 px-4 border rounded-full hover:bg-gray-200 cursor-pointer' onClick={() => setApproveConfirm(false)}>Cancel</div>
                                    <div className='p-1 px-4 border border-green-600 rounded-full hover:bg-green-600 hover:text-white cursor-pointer' onClick={handleApprove}>Approve</div>
                                </div>
                            </div>
                        </div>
                    )}
                    {rejectConfirm && (
                        <div className='absolute top-0 left-0 w-full h-full bg-black/80 z-20 flex justify-center items-center'>
                            <div className='flex flex-col gap-8 items-center bg-white p-12 rounded-xl'>
                                <div className='text-xl'>Are you sure you want to reject the receipt?</div>
                                <div className='flex justify-between w-full'>
                                    <div className='p-1 px-4 border rounded-full hover:bg-gray-200 cursor-pointer' onClick={() => setRejectConfirm(false)}>Cancel</div>
                                    <div className='p-1 px-4 border border-red-600 rounded-full hover:bg-red-600 hover:text-white cursor-pointer' onClick={handleReject}>Reject</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </div>)
}