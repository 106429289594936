import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from "../../utils/axiosBaseQuery";

export const auditLogApi = createApi({
    reducerPath: 'auditLogApi',
    baseQuery: axiosBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/admin/audit_logs`,
    }),
    endpoints: (build) => ({
        getAuditLogs: build.query({
            query({ params }) {
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `?${queryParams}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Audit Logs']
        }),
        getAuditLogById: build.query({
            query({ id }) {
                return {
                    url: `/${id}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Audit Log']
        }),
    }),
});

export const {
    useGetAuditLogsQuery,
    useGetAuditLogByIdQuery
} = auditLogApi;