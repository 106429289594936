import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '../../utils/axiosBaseQuery';

export const homepageApi = createApi({
    reducerPath: 'homepageApi',
    baseQuery: axiosBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api`,
    }),
    endpoints: (build) => ({
        getInformation: build.query({
            query(params) {
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/admin/home-page`,
                    method: 'GET'
                }
            },
            providesTags: ['Notifications']
        }),
    }),
});

export const { useGetInformationQuery } = homepageApi;