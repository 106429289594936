import AnalyticsListItem from "./AnalyticsListItem";

export default function AnalyticsList({ analytics }) {
    return (
        <>
            {analytics.map(item => (
                <AnalyticsListItem key={item.id} item={item} />
            ))}
        </>
    )
}   