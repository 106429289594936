import { FiUsers } from "react-icons/fi";
import { IoRibbonOutline } from "react-icons/io5";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { IoNotificationsOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { menuActions } from "../store/menuSlice";
import { IoAnalytics } from "react-icons/io5";
import { CiUser } from "react-icons/ci";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { CiLogout } from "react-icons/ci";
import { authActions } from "../store/authSlice";
import { FaRegChartBar } from "react-icons/fa";

export default function Menu() {

    const isBigMenu = useSelector(state => state.menu.isBigMenu)
    const activeCategory = useSelector(state => state.menu.activeCategory)
    const token = localStorage.getItem('giz_superaccess')
    const [isLogoutScreenActive, setIsLogoutScreenActive] = useState(false)
    const navigate = useNavigate()
    const [isDetailsExpanded, setIsDetailsExpanded] = useState(false)

    const user = jwtDecode(token);

    const dispatch = useDispatch();

    const handleCategorySelect = (e) => {
        e.stopPropagation();
        const category = e.currentTarget.getAttribute("category")
        dispatch(menuActions.changeActiveCategory({ category: category }))
        setIsDetailsExpanded(false)
    }

    const handleMenuChange = () => {
        if (!isBigMenu) {
            setIsLogoutScreenActive(false)
        }
        dispatch(menuActions.changeMenuSize())
    }

    const handleProfileClick = (e) => {
        e.stopPropagation();
        setIsLogoutScreenActive(!isLogoutScreenActive)
    }

    const handleLogout = () => {
        dispatch(authActions.removeToken())
        dispatch(menuActions.changeActiveCategory(''))
        localStorage.removeItem('giz_superaccess')
        localStorage.removeItem('giz_superrefresh')
        navigate('/login')
    }

    // const handleDetailsClick = (e) => {
    //     e.stopPropagation();
    //     setIsDetailsExpanded(!isDetailsExpanded)
    // }

    const handleDetailsSubCategoryClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const sub_category = e.target.getAttribute('name')
        navigate(`/${sub_category}`)
    }

    return (
        <div className={`${isBigMenu ? 'w-[280px]' : 'w-auto'} transition-all h-full flex-none border border-gray-200 rounded-xl flex flex-col justify-between cursor-pointer bg-white transition-all`} onClick={handleMenuChange}>
            <div>
                <div className={`flex p-6`}>
                    <img src="/pics/atk.svg" alt="" width={32} onClick={(e) => {
                        e.stopPropagation();
                        dispatch(menuActions.changeActiveCategory({ category: '' }))
                        navigate('/')
                    }} />
                    {/* {isBigMenu && (
                        <img src="/pics/atk-long.svg" alt="" width={170} onClick={(e) => {
                            e.stopPropagation();
                            dispatch(menuActions.changeActiveCategory({ category: '' }))
                            navigate('/')
                        }} />
                    )} */}
                </div>
                <div className="p-4 flex flex-col gap-1">
                    <div category="users" onClick={handleCategorySelect} title="Users">
                        <Link to="/users" className="w-full flex gap-4 items-center cursor-pointer">
                            <div className={`p-[12px] border border-white rounded-xl ${activeCategory === 'users' ? 'bg-primary border-primary text-white' : 'text-gray-400 hover:bg-gray-100'}`}>
                                <FiUsers style={{ fontSize: '24px' }} />
                            </div>
                            {isBigMenu && <div className="text-sm font-semibold">Users</div>}
                        </Link>
                    </div>
                    <div category="submitted_receipts" onClick={handleCategorySelect} title="Submitted Receipts">
                        <Link to="/submitted_receipts" className="w-full flex gap-4 items-center cursor-pointer">
                            <div className={`p-3 border border-white rounded-xl ${activeCategory === 'submitted_receipts' ? 'bg-primary border-primary text-white' : 'text-gray-400 hover:bg-gray-100'}`}>
                                <HiOutlineDocumentText style={{ fontSize: '24px' }} />
                            </div>
                            {isBigMenu && <div className="text-sm font-semibold">Submitted Receipts</div>}
                        </Link>
                    </div>
                    <div category="archived_receipts" onClick={handleCategorySelect} title="Archived Receipts">
                        <Link to="/archived_receipts" className="w-full flex gap-4 items-center cursor-pointer">
                            <div className={`p-3 border border-white rounded-xl ${activeCategory === 'archived_receipts' ? 'bg-primary border-primary text-white' : 'text-gray-400 hover:bg-gray-100'}`}>
                                <HiOutlineDocumentCheck style={{ fontSize: '24px' }} />
                            </div>
                            {isBigMenu && <div className="text-sm font-semibold">Archived Receipts</div>}
                        </Link>
                    </div>
                    <div category="prizes" onClick={handleCategorySelect} title="Prizes">
                        <Link to="/prizes" className="w-full flex gap-4 items-center cursor-pointer">
                            <div className={`p-3 border border-white rounded-xl ${activeCategory === 'prizes' ? 'bg-primary border-primary text-white' : 'text-gray-400 hover:bg-gray-100'}`}>
                                <IoRibbonOutline style={{ fontSize: '24px' }} />
                            </div>
                            {isBigMenu && <div className="text-sm font-semibold">Prizes</div>}
                        </Link>
                    </div>
                    <div category="notifications" onClick={handleCategorySelect} title="Notifications">
                        <Link to="/notifications" className="w-full flex gap-4 items-center cursor-pointer">
                            <div className={`p-3 border border-white rounded-xl ${activeCategory === 'notifications' ? 'bg-primary border-primary text-white' : 'text-gray-400 hover:bg-gray-100'}`}>
                                <IoNotificationsOutline style={{ fontSize: '24px' }} />
                            </div>
                            {isBigMenu && <div className="text-sm font-semibold">Notifications</div>}
                        </Link>
                    </div>
                    <div category="analytics" onClick={handleCategorySelect} title="Analytics">
                        <Link to="/analytics" className="w-full flex gap-4 items-center cursor-pointer">
                            <div className={`p-3 border border-white rounded-xl ${activeCategory === 'analytics' ? 'bg-primary border-primary text-white' : 'text-gray-400 hover:bg-gray-100'}`}>
                                <IoAnalytics style={{ fontSize: '24px' }} />
                            </div>
                            {isBigMenu && <div className="text-sm font-semibold">Analytics</div>}
                        </Link>
                    </div>
                    <div category="performance" onClick={handleCategorySelect} title="Performance">
                        <Link to="/performance" className="w-full flex gap-4 items-center cursor-pointer">
                            <div className={`p-3 border border-white rounded-xl ${activeCategory === 'performance' ? 'bg-primary border-primary text-white' : 'text-gray-400 hover:bg-gray-100'}`}>
                                <FaRegChartBar style={{ fontSize: '24px' }} />
                            </div>
                            {isBigMenu && <div className="text-sm font-semibold">Performance</div>}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="w-full p-4">
                <div className="w-full items-center flex gap-4" onClick={handleProfileClick}>
                    {/* <img src="/pics/user_profile.png" alt="img" className="w-[48px] h-[48px] rounded-full object-cover" /> */}
                    <div className="rounded-full border border-gray-200 p-2">
                        <CiUser fontSize={30} color="gray" />
                    </div>
                    {isBigMenu && <div className="font-semibold transition-all text-sm hover:underline">
                        <span>{user.name} ↓</span>
                    </div>}
                </div>
                <div className={`flex gap-2 border border-gray-200 items-center justify-center hover:border-red-200 hover:bg-red-50 text-red-600 rounded-lg ${isBigMenu && isLogoutScreenActive ? "transition-all max-h-48 max-w-82 p-2 mt-4" : 'max-h-0 max-w-0 overflow-hidden'}`} onClick={handleLogout}>Logout <CiLogout /></div>
            </div>
        </div>
    )
}